*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
  padding: 20px;
  color: #3b5441;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.userInfo,
._loginRegister{
  background-color: #ffffff;
  padding: 20px;
  max-width: 400px;
  margin: auto;
  border-radius: 3px;
  box-shadow: 0 1px rgba(255, 255, 255, .2) inset, 0 2px 2px -1px rgba(0, 0, 0, .3);
}

._loginRegister h1{
  text-align: center;
  margin: 0 0 20px 0;
}

._loginRegister .form-control{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

._loginRegister .form-control label{
  font-weight: bold;
  margin-bottom: 3px;
}

[type="text"],
[type="email"],
[type="phone"],
[type="password"]{
  padding:7px 10px;
  outline: none;
  margin-bottom: 5px;
  font-size: 16px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, .3);
}

[type="text"]:hover,
[type="text"]:focus,
[type="email"]:hover,
[type="email"]:focus,
[type="phone"]:hover,
[type="phone"]:focus,
[type="password"]:hover,
[type="password"]:focus
{
  border-color: #107a8b;
}

._navBtn button,
[type="submit"]{
  background: #ff487e;
  color: #ffffff;
  border: 1px solid rgba(0, 0, 0, .1);
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 2px;
  margin-top: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  outline: none;
}
._navBtn button:hover,
[type="submit"]:hover{
  opacity: .9;
}

._navBtn{
  border-top: 1px solid #dedede;
  margin-top: 5px;
}
._navBtn button{
  background: #555555;
  color: #ffffff;
  width: 100%;
  
}

.success-msg,
.error-msg{
  border: 1px solid #ff304f;
  padding: 10px;
  color: #ff304f;
  font-weight: bold;
  border-radius: 2px;
}

.success-msg{
  color: #24b273;
  border-color: #24b273;
}

.userInfo{
  text-align: center;
}

.userInfo ._img{
  font-size: 50px;
}
.userInfo h1{
  text-transform: capitalize;
  margin: 10px 0;
}
.userInfo ._email{
  margin: 10px 0;
  color: #555555;
}
.userInfo button{
  margin-top: 5px;
  background: #ff304f;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 16px;
  border: 5px solid rgba(0, 0, 0, .1);
  outline: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  transition: background .3s;
}
.userInfo button:hover{
  border-color: #ff304f;
  color: #ff304f;
  background: #ffffff;
}
